import * as React from "react";
import Layout from "../components/layout";
import Team from "../components/team";

const HeadOfSoundDesign = () => {
  return (
    <Layout>
      <h2 className="text-3xl font-bold text-left">Head of Sound Design</h2>
      <Team />
    </Layout>
  );
};

export default HeadOfSoundDesign;

export const Head = () => <title>Head of Sound Design</title>;
